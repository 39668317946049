/**
 * 后端鉴权后返回页
 * */
import qs from 'qs';
import Utils from 'common/utils';
import { useStore } from 'idpBase/store';

const RedirectIdentityTransfer = () => {
  const { rootStore } = useStore();

  const queryObj = qs.parse(_.trimStart(window.location.search || '', '?'));
  const setPwd = m.get(queryObj, 'set_password');

  React.useEffect(() => {
    // PC 端父窗口消失
    if (!window.opener) {
      // 如果没有父窗口，那么就直接跳转登录
      rootStore.redirectToLandingPage();
      return Utils.noop;
    }

    // 认证源登录会打开一个窗口，这里通过 postMessage 来根主窗口通信
    window.opener.postMessage(
      {
        type: 'POPUP_AUTH_SUCCESS',
        data: {
          isSetPassword: setPwd,
        },
      },
      '*',
    );
    return Utils.noop;
  }, []);

  return null;
};

export default React.memo(RedirectIdentityTransfer);
